import React from "react";
import "./UnderConstruction.css"; // Add custom styles here

const UnderConstruction = () => {
  return (
    <div className="construction-container">
      <div className="text-section">
        <h1>Oops!</h1>
        <h2>Under construction</h2>
        <p>
        We're Building Something Amazing!
        </p>
        {/* <button className="info-button">More Info</button> */}
      </div>
      <div className="image-section">
        {/* Replace the src with your vector asset path */}
        <img src="/img/Under construction-bro.svg" alt="Under Construction" />
      </div>
    </div>
  );
};

export default UnderConstruction;
