
import './App.css';

import UnderConstruction from './UnderConstruction/UnderConstruction';

function App() {

  
  return (
    <div className="App">
   <UnderConstruction/>
    </div>
  );
}

export default App;
